import React from "react";
import { Consent } from "../Components";
import { Partners } from "../Helpers";
import moment from "moment";
import API from "../Modules/API";
import { connect } from "react-redux";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      count: 0,
      email: "",
      buttons1: 1,
      buttons2: 1,
    };
  }

  componentDidMount() {
    fetch("https://kosodu.com/api/count")
      .then((result) => result.json())
      .then((result) => this.setState({ count: result.data }));

    const options = {
      method: "GET",
      headers: {
        "X-RapidAPI-Host": "covid-193.p.rapidapi.com",
        "X-RapidAPI-Key": "6885d747d1mshe1a8038c13689fbp1b8044jsn8c1fe84bebfb",
      },
    };
    API.partners();
  }

  register = async (e) => {
    e.preventDefault();
    if (this.state.email.includes("@") && this.state.email.includes(".")) {
      fetch("https://kosodu.com/api/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.state.email,
        }),
      }).then(
        (result) => (window.location = "https://kosodu.com/api/subscribe")
      );
    }
  };

  render() {
    return (
      <div id="app">
        <Consent />
        <header>
          <div
            className="container"
            margin="large"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <a href="/">
              <img src="/assets/i/logo.svg" className="logo" />
            </a>
            <a href="/blog">
              <div className="switch">
                <div className="switch__item">Blog</div>
              </div>
            </a>
          </div>
          <div className="container">
            <div className="row between-xs">
              <div className="col-xs-12 col-sm-12 col-md-4">
                <h1>Türkiye Korona Son Durumu</h1>
                <h2 className="subtitle" id="dinamic--today">
                  Bugün - 11 Mart 2020
                </h2>
                <h1 className="display" id="display">
                  <span className="dinamic" id="dinamic--date-new">
                    4.907
                  </span>{" "}
                  Yeni Vaka ile toplam vaka sayısı{" "}
                  <span className="dinamic" id="dinamic--date-total">
                    4.907
                  </span>{" "}
                  olmuş durumda.
                </h1>
                <div className="icons" margin="large">
                  <div className="icon" onClick={() => window.function_prev()}>
                    <i data-feather="arrow-left" data="next" bind="display"></i>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-arrow-left"
                      >
                        <line x1="19" y1="12" x2="5" y2="12" />
                        <polyline points="12 19 5 12 12 5" />
                      </svg>
                      <label
                        style={{ marginLeft: "3px", marginBottom: "2px" }}
                        htmlFor=""
                      >
                        Geri
                      </label>
                    </div>
                  </div>
                  <div className="icon" onClick={() => window.function_next()}>
                    <i
                      data-feather="arrow-right"
                      data="prev"
                      bind="display"
                    ></i>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label
                        style={{ marginRight: "3px", marginBottom: "2px" }}
                        htmlFor=""
                      >
                        İleri
                      </label>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-arrow-right"
                      >
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <polyline points="12 5 19 12 12 19" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="row" margin="large">
                  <div className="col-xs-12 col-sm-6 col-md-6">
                    <span className="display">🇹🇷</span>
                    <h2 className="title">Türkiye</h2>
                    <p>
                      Türkiye’de toplam vaka sayısı{" "}
                      <span className="dinamic" id="dinamic--total">
                        60.000
                      </span>
                      ,
                      <br />
                      ölüm sayısı{" "}
                      <span className="dinamic" id="dinamic--total-deaths">
                        60.000
                      </span>{" "}
                    </p>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-6">
                    <span className="display">🌍</span>
                    <h2 className="title">Dünya Geneli</h2>
                    <p>
                      Dünya genelindeki toplam vaka sayısı{" "}
                      <span
                        className="dinamic"
                        id="dinamic--world-summary-total"
                      >
                        {}{" "}
                      </span>
                      , <br />
                      ölüm sayısı{" "}
                      <span
                        className="dinamic"
                        id="dinamic--world-summary-total-deaths"
                      >
                        60.000
                      </span>{" "}
                    </p>
                  </div>
                </div>
                <div className="icons">
                  <a href="#" className="icon">
                    <i data-feather="facebook" className="feather--fill"></i>
                  </a>
                  <a href="#" className="icon">
                    <i data-feather="twitter" className="feather--fill"></i>
                  </a>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-8">
                <h1>Dünya Korona Son Durumu</h1>
                <div className="responsive">
                  <table className="table" id="latest">
                    <thead>
                      <th>
                        <label>Ülke/Bölge</label>
                      </th>
                      <th>
                        <label>Toplam Vaka</label>
                      </th>
                      <th>
                        <label>Yeni Vaka</label>
                      </th>
                      <th>
                        <label>Toplam Ölüm</label>
                      </th>
                      <th>
                        <label>Yeni Ölüm</label>
                      </th>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section>
          <div className="container">
            <div className="row between-xs middle-xs">
              <div className="col-xs-12 col-sm-12 col-md-7" margin="small">
                <div className="canvas">
                  <h4>Türkiye Korona Virüs Durumu</h4>
                  <canvas id="stats" height="300"></canvas>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-4" margin="small">
                <h1>
                  Türkiye genelindeki vaka, ölüm ve iyileşme sayılarının
                  istatistikleri.
                </h1>

                <div className="switch">
                  <div
                    className={
                      this.state.buttons1 === 1
                        ? "switch__item active"
                        : "switch__item"
                    }
                    data="total"
                    bind="general"
                    onClick={() => {
                      window.countryDataLatest("total");
                      this.setState({ buttons1: 1 });
                    }}
                  >
                    Toplam
                  </div>
                  <div
                    className={
                      this.state.buttons1 === 2
                        ? "switch__item active"
                        : "switch__item"
                    }
                    data="today"
                    bind="general"
                    id="today-button"
                    onClick={() => {
                      window.countryDataLatest("today");
                      this.setState({ buttons1: 2 });
                    }}
                  >
                    Bugün{" "}
                  </div>
                  <div
                    className={
                      this.state.buttons1 === 3
                        ? "switch__item active"
                        : "switch__item"
                    }
                    data="yesterday"
                    bind="general"
                    id="yesterday-button"
                    onClick={() => {
                      window.countryDataLatest("yesterday");
                      this.setState({ buttons1: 3 });
                    }}
                  >
                    Dün
                  </div>
                </div>
                <div content="general">
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="card--small">
                        <div className="card--small__image">
                          <img src="assets/i/icons/top-1.svg" />
                        </div>
                        <div className="card--small__content">
                          <h1 className="display" id="dinamic--country-cases">
                            63.000
                          </h1>
                          <h2
                            className="subtitle"
                            id="dinamic--country-cases__label"
                          >
                            Toplam Vaka
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12">
                      <div className="card--small">
                        <div className="card--small__image">
                          <img src="assets/i/icons/top-2.svg" />
                        </div>
                        <div className="card--small__content">
                          <h1 className="display" id="dinamic--country-deaths">
                            63.000
                          </h1>
                          <h2
                            className="subtitle"
                            id="dinamic--country-deaths__label"
                          >
                            Toplam Vaka
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12">
                      <div className="card--small">
                        <div className="card--small__image">
                          <img src="assets/i/icons/top-3.svg" />
                        </div>
                        <div className="card--small__content">
                          <h1
                            className="display"
                            id="dinamic--country-recovered"
                          >
                            63.000
                          </h1>
                          <h2
                            className="subtitle"
                            id="dinamic--country-recovered__label"
                          >
                            Toplam Vaka
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <h1 className="display">Koronavirüs Önlemleri</h1>
                <p className="large">
                  Sağlığınızı ve sevdiklerinizi korumanız için şu 14 kuralı asla
                  ama asla unutmayın. #MaskeTakın
                </p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-8 col-lg-9">
                <div className="row">
                  <div className="col-xs-6 col-sm-6 col-md-4" margin="large">
                    <div className="icon--square">
                      <img src="assets/i/icons/qaution-1.svg" />
                    </div>
                    <h2 className="title">Temizlik</h2>
                    <p>
                      Ellerinizi sık sık, su ve sabun ile en az otuz saniye
                      boyunca ovarak yıkayın.
                    </p>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-4" margin="large">
                    <div className="icon--square">
                      <img src="assets/i/icons/qaution-2.svg" />
                    </div>
                    <h2 className="title">Mesafe</h2>
                    <p>
                      Soğuk algınlığı belirtileri gösteren kişilerle aranıza en
                      az 2m mesafe koyun.
                    </p>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-4" margin="large">
                    <div className="icon--square">
                      <img src="assets/i/icons/qaution-3.svg" />
                    </div>
                    <h2 className="title">Öksürme ve Hapşırma</h2>
                    <p>
                      Öksürme ve hapşırma sırasında ağzınızı, burnunuzu tek
                      kullanımlık mendille kapatın. Mendil yoksa dirsek içini
                      kullanın.
                    </p>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-4" margin="large">
                    <div className="icon--square">
                      <img src="assets/i/icons/qaution-4.svg" />
                    </div>
                    <h2 className="title">Tokalaşma</h2>
                    <p>Tokalaşma, sarılma gibi yakın temaslardan kaçının.</p>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-4" margin="large">
                    <div className="icon--square">
                      <img src="assets/i/icons/qaution-5.svg" />
                    </div>
                    <h2 className="title">El, Ağız & Burun</h2>
                    <p>
                      Ellerinizle gözlerinize, ağzınıza ve burnunuza dokunmayın.
                    </p>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-4" margin="large">
                    <div className="icon--square">
                      <img src="assets/i/icons/qaution-6.svg" />
                    </div>
                    <h2 className="title">Yurtdışı</h2>
                    <p>Yurt dışı seyahatlerinizi iptal edin ya da erteleyin.</p>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-4" margin="large">
                    <div className="icon--square">
                      <img src="assets/i/icons/qaution-7.svg" />
                    </div>
                    <h2 className="title">İzolasyon</h2>
                    <p>Yurt dışından dönüşte ilk 14 günü evinizde geçirin.</p>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-4" margin="large">
                    <div className="icon--square">
                      <img src="assets/i/icons/qaution-8.svg" />
                    </div>
                    <h2 className="title">Havalandırma</h2>
                    <p>Bulunduğunuz ortamları sık sık havalandırın.</p>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-4" margin="large">
                    <div className="icon--square">
                      <img src="assets/i/icons/qaution-9.svg" />
                    </div>
                    <h2 className="title">Yıkama</h2>
                    <p>
                      Kıyafetlerinizi 60-90 derecede normal deterjanla yıkayın.
                    </p>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-4" margin="large">
                    <div className="icon--square">
                      <img src="assets/i/icons/qaution-10.svg" />
                    </div>
                    <h2 className="title">Kapı Kolları</h2>
                    <p>
                      Kapı kolları, armatürler lavabolar gibi sık kullandığınız
                      yüzeyleri su ve deterjanla her gün temizleyin.
                    </p>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-4" margin="large">
                    <div className="icon--square">
                      <img src="assets/i/icons/qaution-11.svg" />
                    </div>
                    <h2 className="title">Belirtiler</h2>
                    <p>
                      Soğuk algınlığı belirtileriniz varsa yaşlılara ve kronik
                      hastalığı olanlarla temas etmeyin ve maske takmadan dışarı
                      çıkmayın.
                    </p>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-4" margin="large">
                    <div className="icon--square">
                      <img src="assets/i/icons/qaution-12.svg" />
                    </div>
                    <h2 className="title">Ortak Eşyalar</h2>
                    <p>Havlu gibi kişisel eşyalarınızı ortak kullanmayın.</p>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-4" margin="large">
                    <div className="icon--square">
                      <img src="assets/i/icons/qaution-13.svg" />
                    </div>
                    <h2 className="title">Beslenme</h2>
                    <p>
                      Bol sıvı tüketin, dengeli beslenin, uyku düzeninize dikkat
                      edin.
                    </p>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-4" margin="large">
                    <div className="icon--square">
                      <img src="assets/i/icons/qaution-14.svg" />
                    </div>
                    <h2 className="title">Ateş</h2>
                    <p>
                      Düşmeyen ateş, öksürük ve nefes darlığınız varsa, maske
                      takarak bir sağlık kuruluşuna başvurun.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <h1 className="display" center="center">
              Türkiye Korona Virüs Tarihsel Verileri
            </h1>
            <p className="large" center="center">
              Türkiye için koronavirüs ilk vakasından itibarenki tarihsel tüm
              veriler ve değişimleri.
            </p>
            <div center="center">
              <div
                className="switch"
                style={{ display: "none" }}
                center="center"
                align="center"
                justify="center"
              >
                <div
                  className={
                    this.state.buttons2 === 1
                      ? "switch__item active"
                      : "switch__item"
                  }
                  onClick={() => {
                    window.function_table();
                    this.setState({ buttons2: 1 });
                  }}
                  data="table"
                  bind="graph-or-table"
                >
                  Tablo
                </div>
                <div
                  className={
                    this.state.buttons2 === 2
                      ? "switch__item active"
                      : "switch__item"
                  }
                  onClick={() => {
                    window.function_graph();
                    this.setState({ buttons2: 2 });
                  }}
                  data="graph"
                  bind="graph-or-table"
                >
                  Hız Grafiği
                </div>
              </div>
            </div>
            <div id="logaritmic_view" style={{ display: "none" }}>
              <h4>Türkiye Koronavirüs Hız Durumu</h4>
              <canvas id="logaritmic" style={{ display: "none" }}></canvas>
            </div>
            <div className="responsive" id="graph-or-table">
              <table className="table" id="generic">
                <thead>
                  <th>
                    <label>Tarih</label>
                  </th>

                  <th>
                    <label>Yeni Vaka</label>
                  </th>

                  <th>
                    <label>Yeni İyileşen</label>
                  </th>

                  <th>
                    <label>Yeni Ölüm</label>
                  </th>
                </thead>
              </table>
            </div>
          </div>
        </section>
        <section style={{ background: "#f7f7f7" }}>
          <div className="container">
            <h1 className="display" center="center">
              Karşılaştırma
            </h1>
            <p className="large" center="center">
              Türkiye'deki Korona Durumunun Dünyadaki Korona Durumu İle
              Karşılaştırması.
            </p>
            <p className="font-20 bold" center="center">
              <span className="subtitle font-20 bold">Tarih: </span>
              <span className="subtitle font-20 bold" id="compare--date">
                11 Mart 2020
              </span>
            </p>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6">
                <div className="row center-xs middle-xs">
                  <div
                    className="col-xs-12 col-sm-12 col-md-8 col-lg-8"
                    margin="small"
                  >
                    <h1 className="display" left="left">
                      🇹🇷 Türkiye
                    </h1>
                    <div className="row" left="left">
                      <div className="col-xs-6" margin="small">
                        <h2 id="dinamic--compare-country-total-cases">
                          63.000
                        </h2>
                        <h2 className="subtitle">Toplam Vaka</h2>
                      </div>
                      <div className="col-xs-6" margin="small">
                        <h2 id="dinamic--compare-country-new-cases">63.000</h2>
                        <h2 className="subtitle">Yeni Vaka</h2>
                      </div>
                      <div className="col-xs-6" margin="small">
                        <h2 id="dinamic--compare-country-total-deaths">
                          63.000
                        </h2>
                        <h2 className="subtitle">Toplam Ölüm</h2>
                      </div>
                      <div className="col-xs-6" margin="small">
                        <h2 id="dinamic--compare-country-new-deaths">63.000</h2>
                        <h2 className="subtitle">Yeni Ölüm</h2>
                      </div>
                      {/* <div className="col-xs-6" margin="small">
                        <h2 id="dinamic--compare-country-total-recovered">
                          63.000
                        </h2>
                        <h2 className="subtitle">Toplam İyileşen</h2>
                      </div>
                      <div className="col-xs-6" margin="small">
                        <h2 id="dinamic--compare-country-new-recovered">
                          63.000
                        </h2>
                        <h2 className="subtitle">Yeni İyileşen</h2>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6">
                <div className="row center-xs middle-xs">
                  <div
                    className="col-xs-12 col-sm-12 col-md-8 col-lg-8"
                    margin="small"
                  >
                    <h1 className="display" left="left">
                      🌍 Dünya Geneli
                    </h1>
                    <div className="row" left="left">
                      <div className="col-xs-6" margin="small">
                        <h2 id="dinamic--compare-world-total-cases">63.000</h2>
                        <h2 className="subtitle">Toplam Vaka</h2>
                      </div>
                      <div className="col-xs-6" margin="small">
                        <h2 id="dinamic--compare-world-new-cases">63.000</h2>
                        <h2 className="subtitle">Yeni Vaka</h2>
                      </div>
                      <div className="col-xs-6" margin="small">
                        <h2 id="dinamic--compare-world-total-deaths">63.000</h2>
                        <h2 className="subtitle">Toplam Ölüm</h2>
                      </div>
                      <div className="col-xs-6" margin="small">
                        <h2 id="dinamic--compare-world-new-deaths">63.000</h2>
                        <h2 className="subtitle">Yeni Ölüm</h2>
                      </div>
                      {/* <div className="col-xs-6" margin="small">
                        <h2 id="dinamic--compare-world-total-recovered">
                          63.000
                        </h2>
                        <h2 className="subtitle">Toplam İyileşen</h2>
                      </div>
                      <div className="col-xs-6" margin="small">
                        <h2 id="dinamic--compare-world-new-recovered">
                          63.000
                        </h2>
                        <h2 className="subtitle">Yeni İyileşen</h2>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <h1 className="display" center="center">
              Türkiye'deki Korona Durumunun günlük gelişmelerinden haberdar
              olmak için bültenimize abone olun.
            </h1>
            <p className="large" center="center" margin="medium">
              Günlük e-mailler ile gelişmelerden haberdar olun ve istatistikleri
              takip edin.
            </p>
            <form action="https://kosodu.com/api/subscribe" method="post">
              <div flex="flex" align="end" justify="center" left="left">
                <div className="group">
                  <label>Email</label>
                  <input className="input" type="email" required name="email" />
                </div>
                <div className="group">
                  <button href="#" className="button" type="submit">
                    Abone Ol
                  </button>
                </div>
              </div>
            </form>
            <div flex="flex" align="center" justify="center" left="left">
              <label htmlFor="">
                <span id="email-subscribers-count">{this.state.count}</span>{" "}
                abone bulunmaktadır.
              </label>
            </div>
            <div
              className="group"
              center="center"
              flex="flex"
              align="center"
              justify="center"
              left="left"
            >
              <label className="label">
                Abone olarak{" "}
                <a
                  target="_blank"
                  href="/static/privacy-policy"
                  className="link"
                >
                  Gizlilik Sözleşmesi
                </a>
                ’ni okumuş ve kabul etmiş olursunuz.
              </label>
            </div>
          </div>
        </section>

        <div className="footer">
          <div className="container">
            <footer style={{ paddingBottom: "20px" }}>
              <div>
                <p>
                  <a
                    target="_blank"
                    className="link link--label"
                    href="https://re4mer.com/"
                  >
                    © RE4MER
                  </a>
                </p>
                <p className="link link--label">info@kosodu.com</p>
              </div>

              <div flex="flex" align="center" spacing="spacing">
                <a
                  href="/static/privacy-policy"
                  className="menu__item link link--label"
                >
                  Gizlilik Sözleşmesi
                </a>
                <a
                  href="/static/terms-of-use"
                  className="menu__item link link--label"
                >
                  Kullanım Koşulları
                </a>
              </div>
            </footer>
            <hr />
            <footer style={{ paddingTop: "15px" }} className="subFooter">
              {this.props.redux.partners
                .map((i) => (
                  <a
                    style={
                      i.path === "https://kosodu.com/"
                        ? { display: "none" }
                        : {}
                    }
                    target="_blank"
                    href={i.path}
                    className="link link--label"
                  >
                    {i.text}
                  </a>
                ))
                .reduce((prev, curr) => [prev, " | ", curr])}
            </footer>

            <footer style={{ paddingTop: "15px" }} className="mSubFooter">
              <div className="row">
                {this.props.redux.partners.map((i) => (
                  <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                    <a
                      target="_blank"
                      href={i.path}
                      className="link link--label"
                    >
                      {i.text}
                    </a>
                  </div>
                ))}
              </div>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map)(Component);
