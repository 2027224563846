import React from "react";
import { Container, Row, Col } from "react-grid-system";
import {
  Header,
  Paragraph,
  Button,
  Subtitle,
  Text,
  Align,
} from "../Components";
import { Colors, Types } from "../Helpers";
import { API } from "../Modules";

class Component extends React.PureComponent {
  render() {
    return (
      <Container>
        <Row align="center" justify="center">
          <Col xs={12} sm={12} md={8} lg={6}>
            <Subtitle bottom={16}>Rastgele Siteler</Subtitle>
            <Header bottom={32}>Her ruh haline uygun rastgele siteler</Header>
            <Paragraph bottom={48}>
              Vakit geçirmek, şaşırmak, yeni şeyler öğrenmek veya eğlenmek mi
              istiyorsunuz? Hepsi bir tık uzağınızda!
            </Paragraph>
            <Row style={{ marginBottom: 24 }}>
              <Col x={6}>
                <Button
                  type="large"
                  background="rgba(0,194,209,0.4)"
                  color={Colors.black}
                  click={() => API.websites(Types.consuming)}
                >
                  <Align direction="column">
                    <Text>Vakit Öldürmek</Text>
                    <Text size={12} line={18}>
                      İstiyorum
                    </Text>
                  </Align>
                </Button>
              </Col>
              <Col x={6}>
                <Button
                  type="large"
                  background="rgba(249,233,0,0.4)"
                  color={Colors.black}
                  click={() => API.websites(Types.helpful)}
                >
                  <Align direction="column">
                    <Text>Birşeyler Öğrenmek</Text>
                    <Text size={12} line={18}>
                      İstiyorum
                    </Text>
                  </Align>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col x={6}>
                <Button
                  type="large"
                  background="rgba(245,93,63,0.4)"
                  color={Colors.black}
                  click={() => API.websites(Types.fun)}
                >
                  <Align direction="column">
                    <Text>Biraz Eğlenmek</Text>
                    <Text size={12} line={18}>
                      İstiyorum
                    </Text>
                  </Align>
                </Button>
              </Col>
              <Col x={6}>
                <Button
                  type="large"
                  background={Colors.russian}
                  click={() => API.websites(Types.interesting)}
                >
                  <Align direction="column">
                    <Text color={Colors.white}>Beni Şaşırtmanı</Text>
                    <Text color={Colors.white} size={12} line={18}>
                      İstiyorum
                    </Text>
                  </Align>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Component;
