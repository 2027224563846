import React from "react";
import { Colors } from "../Helpers";
import { Logo, Padding, Text } from "../Components";
import { Container, Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";

class Component extends React.PureComponent {
  render() {
    return (
      <div
        style={{
          marginTop: 140,
          paddingTop: 80,
          borderStyle: "solid",
          borderWidth: 0,
          borderTopWidth: 1,
          borderColor: Colors.platinum,
        }}
      >
        <Container>
          <Row>
            <Col xs={12} sm={12} md={4} style={{ marginBottom: 24 }}>
              <Logo />
              <Padding top={24} display="block">
                <Text size={20} line={32} weight="600">
                  hello@randomsites.co
                </Text>
              </Padding>
              <Padding top={24} display="block">
                <Text size={20} line={32} opacity={0.5}>
                  © 2020 Random Sites. All right reserved.
                </Text>
              </Padding>
            </Col>
            <Col xs={12} sm={12} md={4} style={{ marginBottom: 24 }}>
              <Text size={18} line={32} bottom={32} weight="600">
                MENU
              </Text>
              <Row>
                <Col>
                  <Link to="/">
                    {" "}
                    <Text
                      size={18}
                      line={32}
                      bottom={32}
                      weight="400"
                      opacity={0.8}
                    >
                      Home
                    </Text>
                  </Link>
                </Col>
                <Col>
                  <Link to="/blog">
                    <Text
                      size={18}
                      line={32}
                      bottom={32}
                      weight="400"
                      opacity={0.8}
                    >
                      Blog
                    </Text>
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col>
                  <a
                    target="_blank"
                    href="https://kosodu.com/static/privacy-policy"
                  >
                    <Text size={18} line={32} weight="400" opacity={0.8}>
                      Privacy Policy
                    </Text>
                  </a>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={4} style={{ marginBottom: 24 }}>
              <Text size={18} line={32} bottom={32} weight="600">
                SOCIAL
              </Text>
              <Row>
                <Col>
                  <Link to="https://instagram.com/randomsites.co/">
                    <Text
                      size={18}
                      line={32}
                      bottom={32}
                      weight="400"
                      opacity={0.8}
                    >
                      Instagram
                    </Text>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Component;
