import React from "react";
import CookieConsent from "react-cookie-consent";

import { Colors } from "../../Helpers";
import { Text } from "../../Components";

const Element = () => (
  <CookieConsent
    location="bottom"
    buttonText={"Tamam"}
    cookieName="consent"
    style={{ background: "#0E1828" }}
    buttonStyle={{
      padding: "0 16px",
      lineHeight: "48px",
      color: "#0E1828",
      backgroundColor: "white",
      borderRadius: 6,
      fontWeight: "600",
      fontSize: 12,
    }}
    expires={150}
  >
    Bu web sitesi kullanıcı deneyimini arttırmak için çerezler kullanmaktadır.{" "}
    <a
      href={"https://kosodu.com/static/privacy-policy"}
      style={{ color: "white", fontWeight: "700" }}
    >
      Gizlilik Sözleşmesi
    </a>
  </CookieConsent>
);

export default Element;
