import React from "react";

const Component = ({ children, bottom, left, right, top, display = "inline-block" }) => (
  <div
    style={{
      display: display,
      paddingBottom: bottom,
      paddingRight: right,
      paddingLeft: left,
      paddingTop: top,
    }}
  >
    {children}
  </div>
);

export default Component;
