import React from "react";

import { PostContainer, NavbarContainer, FooterContainer } from "../Containers";

class Component extends React.PureComponent {
  render() {
    return (
      <div>
        <header>
          <div
            className="container"
            margin="large"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <a href="/">
              <img src="/assets/i/logo.svg" className="logo" />
            </a>
            <a href="/blog">
              <div className="switch">
                <div className="switch__item">Blog</div>
              </div>
            </a>
          </div>
        </header>
        <PostContainer blog={this.props.match.params.blog} />
        <div className="footer">
          <div className="container">
            <footer>
              <div>
                <p>© Kosodu 2020</p>
                <p>info@kosodu.com</p>
              </div>
              <div flex="flex" align="center" spacing="spacing">
                <a href="/static/privacy-policy" className="menu__item">
                  Gizlilik Sözleşmesi
                </a>
                <a href="/static/terms-of-use" className="menu__item">
                  Kullanım Koşulları
                </a>
              </div>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}

export default Component;
