import React from "react";
import { Link } from "react-router-dom";

const Sources = {
  light: "/i/logo-light.svg",
  dark: "/i/logo-dark.svg",
};

const Component = ({ theme = "dark", size = 40 }) => (
  <Link to="/">
    <img
      src={Sources[theme]}
      style={{ height: size, width: "auto" }}
      alt="Ilao - Istanbul Based Creative Team. We develop web &amp;amp; mobile applications for startups, enterprises and companies."
    />
  </Link>
);

export default Component;
