import React, { useState } from "react";
import { Navigator, Store } from "./Modules";
import { Provider } from "react-redux";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "51%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function App() {
  const [modalIsOpen, setIsOpen] = useState(true);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Provider store={Store}>
      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="modal-adsense"
        >
          <div className="modal">
            <div className="modal-header">
              <a
                href="https://www.youtube.com/watch?v=ijOYFiHKgO4"
                target="_blank"
                className="modal-close"
                onClick={closeModal}
              >
                ✕
              </a>
            </div>
            <div>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/ijOYFiHKgO4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div>
              <p>
                Mustafa Filiz
                <br />
                REACT DERSLERİ, REDUX NEDİR?
              </p>
            </div>
          </div>
        </Modal>
      </div>
      <Navigator />
    </Provider>
  );
}

export default App;
