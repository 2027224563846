import React from "react";

import { Colors } from "../../Helpers";

const Styles = {
  default: {
    display: "inline-block",
    height: 52,
    lineHeight: "52px",
    paddingLeft: 53,
    paddingRight: 52,
    borderStyle: "solid",
    borderRadius: 999,
    borderWidth: 2,
    borderColor: Colors.platinum,
    fontWeight: "600",
    fontSize: 16,
    color: Colors.russian,
    textDecoration: "none",
    cursor: "pointer",
    textDecoration: "none",
    border: "none"
  },
  active: {
    display: "inline-block",
    height: 52,
    lineHeight: "52px",
    paddingLeft: 52,
    paddingRight: 52,
    borderStyle: "solid",
    borderRadius: 999,
    borderWidth: 2,
    borderColor: Colors.russian,
    fontWeight: "600",
    fontSize: 16,
    color: Colors.white,
    textDecoration: "none",
    backgroundColor: Colors.russian,
    cursor: "pointer",
    textDecoration: "none",
    border: "none"
  },
  small: {
    display: "inline-block",
    height: 46,
    lineHeight: "46px",
    paddingLeft: 16,
    paddingRight: 16,
    borderStyle: "solid",
    borderRadius: 4,
    borderWidth: 2,
    borderColor: Colors.russian,
    fontWeight: "600",
    fontSize: 16,
    color: Colors.white,
    textDecoration: "none",
    backgroundColor: Colors.russian,
    cursor: "pointer",
    textDecoration: "none",
    border: "none"
  },
  large: {
    display: "inline-block",
    lineHeight: "18px",
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 11,
    paddingBottom: 11,
    borderRadius: 5,
    borderColor: Colors.platinum,
    fontWeight: "600",
    fontSize: 16,
    color: Colors.white,
    textDecoration: "none",
    cursor: "pointer",
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: Colors.russian,
    textDecoration: "none",
    border: "none"
  },
};

const Component = ({ children, type = "default", background, color, click }) =>
  type === "large" ? (
    <span
      onClick={click}
      style={{
        ...Styles[type],
        backgroundColor: background,
        color: color
      }}
    >
      {children}
    </span>
  ) : (
    <span
      onClick={click}
      style={{
        ...Styles[type],
      }}
    >
      {children}
    </span>
  );

export default Component;
