import React from "react";
import { connect } from "react-redux";
import { Container } from "react-grid-system";
import { Title } from "../Components";
import Markdown from "react-markdown";

class Component extends React.Component {
  render() {
    return (
      <Container style={{ marginBottom: 80 }}>
        <Title bottom={32}>
          {this.props.redux.static ? this.props.redux.static.title : null}
        </Title>
        <Markdown
          source={
            this.props.redux.static ? this.props.redux.static.content : null
          }
        />
      </Container>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
